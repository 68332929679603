<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->

    <div class="container-sm-lot pl-1 pr-1 p-relative">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          ค้นหาผลรางวัล
        </h3>
      </div>
      <div class="text-center">
        <div class="input-search">
          <input
            v-model="lottery_num[0]"
            type="text"
            maxlength="6"
            placeholder="กรอกเลขสลาก 6 หลัก"
          >
          <i class="fas fa-search" />
          <!-- <button @click="ChackReward()">
            <i class="fas fa-search" />
          </button> -->
        </div>

        <div class="input-search">
          <input
            v-model="lottery_num[1]"
            type="text"
            maxlength="6"
            placeholder="กรอกเลขสลาก 6 หลัก"
          >
          <i class="fas fa-search" />
          <!-- <button @click="ChackReward()">
            <i class="fas fa-search" />
          </button> -->
        </div>

        <div class="input-search">
          <input
            v-model="lottery_num[2]"
            type="text"
            maxlength="6"
            placeholder="กรอกเลขสลาก 6 หลัก"
          >
          <i class="fas fa-search" />
          <!-- <button @click="ChackReward()">
            <i class="fas fa-search" />
          </button> -->
        </div>

        <button
          class="check-reward"
          @click="ChackReward()"
        >
          ตรวจผล
        </button>
        <h2 class="text mt-3">
          ผลสลากกินแบ่งรัฐบาล
        </h2>
        <p class="mb-0 text-primary">
          งวดประจำวันที่ {{ CalNameDate(reward[i].date) }}
        </p>

        <span class="select-ctx">
          <button
            class="set-date"
            @click="showOption = !showOption"
          >
            {{ dateCheck.text }}
            <i
              class="fas fa-chevron-down"
              :class="{ 'fa-chevron-up': showOption }"
            />
          </button>

          <ul
            class="option"
            :style="{ display: showOption ? 'block' : 'none' }"
          >
            <li
              v-for="(date, index) in DateList"
              :key="index"
              :class="{ active: selectedDate === date.value }"
              @click="selectDate(date)"
            >
              {{ date.text }}
            </li>
          </ul>
        </span>
      </div>

      <div class="container-sm-lot my-2">
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="text-center">
              <h4 class="text mb-0">
                รางวัลที่ 1
              </h4>
              <p class="mb-0 text-primary">
                1 รางวัล ชุดละ 225,000,000 วอน
              </p>

              <div
                class="d-flex align-items-center justify-content-center my-1"
              >
                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(0) }}
                  </p>
                </div>

                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(1) }}
                  </p>
                </div>

                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(2) }}
                  </p>
                </div>

                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(3) }}
                  </p>
                </div>

                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(4) }}
                  </p>
                </div>

                <div class="reward-golden">
                  <p class="mb-0">
                    {{ reward[i].data.first.number[0].value.charAt(5) }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="text-center">
              <h4 class="text-warning mb-0">
                รางวัลเลขหน้า 3 ตัว
              </h4>
              <p class="mb-0 text-primary">
                2 รางวัล ชุดละละ 150,000 วอน
              </p>
            </div>

            <div class="row my-1">
              <div class="col-6 text-center">
                <p class="-text_reward">
                  {{ reward[i].data.last3f.number[0].value }}
                </p>
              </div>

              <div class="col-6 text-center">
                <p class="-text_reward">
                  {{ reward[i].data.last3f.number[1].value }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="text-center">
              <h4 class="text-warning mb-0">
                รางวัลเลขท้าย 3 ตัว
              </h4>
              <p class="mb-0 text-primary">
                2 รางวัล ชุดละ 150,000 วอน
              </p>
            </div>

            <div class="row my-1">
              <div class="col-6 text-center">
                <p class="-text_reward">
                  {{ reward[i].data.last3b.number[0].value }}
                </p>
              </div>

              <div class="col-6 text-center">
                <p class="-text_reward">
                  {{ reward[i].data.last3b.number[1].value }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <h4 class="text mb-0">
                รางวัลเลขท้าย 2 ตัว
              </h4>
              <p class="mb-0 text-primary">
                1 รางวัล ชุดละ 75,000 วอน
              </p>

              <div
                class="d-flex align-items-center justify-content-center my-1"
              >
                <p class="-text_reward">
                  {{ reward[i].data.last2.number[0].value }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-center">
              <h4 class="text-warning mb-0">
                รางวัลข้างเคียงรางวัลที่ 1
              </h4>
              <p class="mb-0 text-primary">
                2 รางวัล ชุดละ 3,750,000 วอน
              </p>

              <div class="row my-1">
                <div
                  v-for="(item, index) in reward[i].data.near1.number"
                  :key="index"
                  class="col-6 text-center"
                >
                  <p class="-text_reward">
                    {{ item.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div>
              <h4 class="text-warning mb-0">
                รางวัลที่ 2
              </h4>
              <p class="mb-0 text-primary">
                5 รางวัล ชุดละ 7,500,000 วอน
              </p>

              <div class="grid-container my-1">
                <div
                  v-for="(item, index) in reward[i].data.second.number"
                  :key="index"
                  class="grid-item"
                >
                  <p class="-text_rewardx">
                    {{ item.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div>
              <h4 class="text-warning mb-0">
                รางวัลที่ 3
              </h4>
              <p class="mb-0 text-primary">
                10 รางวัล ชุดละ 3,000,000 วอน
              </p>

              <div class="grid-container my-1">
                <div
                  v-for="(item, index) in reward[i].data.third.number"
                  :key="index"
                  class="grid-item"
                >
                  <p class="-text_rewardx">
                    {{ item.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div>
              <h4 class="text-warning mb-0">
                รางวัลที่ 4
              </h4>
              <p class="mb-0 text-primary">
                50 รางวัล ชุดละ 1,500,000 วอน
              </p>

              <div class="grid-container my-1">
                <div
                  v-for="(item, index) in reward[i].data.fourth.number"
                  :key="index"
                  class="grid-item"
                >
                  <p class="-text_rewardx">
                    {{ item.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div>
              <h4 class="text-warning mb-0">
                รางวัลที่ 5
              </h4>
              <p class="mb-0 text-primary">
                100 รางวัลๆละ 750,000 วอน
              </p>

              <div class="grid-container my-1">
                <div
                  v-for="(item, index) in reward[i].data.fifth.number"
                  :key="index"
                  class="grid-item"
                >
                  <p class="-text_rewardx">
                    {{ item.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ThisFooter />

    <!-- modal here -->
    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      centered
      title="Using Component Methods"
    >
      <div class="text-center">
        <div class="text2">
          ตรวจสลากฯ
        </div>
      </div>

      <div class="closed">
        <i
          class="fas fa-times fa-2x text-white cursor-pointer"
          @click="hideModal"
        />
      </div>

      <div class="select-ctx">
        <button
          class="set-date"
          @click="showOption = !showOption"
        >
          {{ dateCheck.text }}
          <i
            class="fas fa-chevron-down"
            :class="{ 'fa-chevron-up': showOption }"
          />
        </button>

        <ul
          class="option"
          :style="{ display: showOption ? 'block' : 'none' }"
        >
          <li
            v-for="(date, index) in DateList"
            :key="index"
            :class="{ active: selectedDate === date.value }"
            @click="selectDate(date)"
          >
            {{ date.text }}
          </li>
        </ul>
      </div>

      <input
        v-model="lottery_num[0]"
        type="tel"
        name="lott_1"
        maxlength="6"
        class="form-check-lot"
        placeholder="กรอกเลขสลากใบที่ 1"
      >

      <input
        v-model="lottery_num[1]"
        type="tel"
        name="lott_1"
        maxlength="6"
        class="form-check-lot"
        placeholder="กรอกเลขสลากใบที่ 2"
      >

      <input
        v-model="lottery_num[2]"
        type="tel"
        name="lott_1"
        maxlength="6"
        class="form-check-lot"
        placeholder="กรอกเลขสลากใบที่ 3"
      >

      <button
        class="btn-golden-radius mt-2 mb-1 w-100"
        @click="ChackReward()"
      >
        ตรวจรางวัล
      </button>
    </b-modal>

    <!-- Modal Welcome -->
    <b-modal
      ref="my-welcome"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="text-center">
        <h3 class="text-primary mt-50">
          ยินดีด้วยค่ะ
        </h3>
        <div>
          <img
            src="@/assets/images/newIcon/welcom.png"
            alt="welcome"
            width="95"
          >
        </div>

        <p
          v-for="(item, index) in chackdata"
          :key="index"
          class="mb-0 text-primary"
        >
          สลากของคุณ ถูก{{ item.reward }} <br>
          มูลค่า {{ item.price }} <br>
        </p>

        <button
          class="btn-gray-txt p-50 w-50 my-1"
          @click="$refs['my-welcome'].hide()"
        >
          ยืนยัน
        </button>
      </div>
    </b-modal>

    <!-- Modal Fail -->
    <b-modal
      ref="my-fail"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="text-center">
        <h3 class="text-primary mt-50">
          เสียใจด้วยค่ะ
        </h3>
        <div>
          <img
            src="@/assets/images/newIcon/sosad.png"
            alt="welcome"
            width="95"
          >
        </div>

        <p class="mb-0 text-primary">
          สลากของคุณ ไม่ถูกรางวัล
        </p>

        <button
          class="btn-gray-txt p-50 w-50 my-1"
          @click="$refs['my-fail'].hide()"
        >
          ยืนยัน
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import moment from 'moment-timezone'
import ThisFooter from './components/ThisFooter.vue'

// import ThisHeader from './components/ThisHeader.vue'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    BModal,
    ThisFooter,
  },
  data() {
    return {
      dateCheck: {},
      selectedDate: '',
      showOption: false,
      Loadmore: false,
      reward: [],
      chackdata: [],
      lottery_num: [],
      DateList: [],
      PeriodsByYear: [],
      i: 0,
    }
  },
  mounted() {
    this.GetReward()
    this.GetPeriodsByYear()
  },
  methods: {
    selectDate(date) {
      // console.log(date)
      this.i = this.findIndexByValue(this.DateList, 'value', date.value)
      // console.log(this.i)
      this.selectedDate = date.value
      this.dateCheck = date
      this.showOption = false
    },
    GetPeriodsByYear() {
      this.$http.get('/lotto-suvarn/GetPeriodsByYear').then(ress => {
        // console.log(ress.data)
        this.PeriodsByYear = ress.data
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.PeriodsByYear.length; i++) {
          const index = this.findIndexByValue(
            this.DateList,
            'value',
            this.PeriodsByYear[i].date,
          )
          if (index < 0) {
            this.DateList.push({
              value: this.PeriodsByYear[i].date,
              text: this.CalNameDate(this.PeriodsByYear[i].date),
            })
          }
        }
        // eslint-disable-next-line prefer-destructuring
        this.dateCheck = this.DateList[0]
        // console.log(this.DateList)
        // ยังไม่เสร็จเหลือแสดงผลตรวจ statusType = 2 คือไม่ถูกรางวัล
      })
    },
    findIndexByValue(array, property, value) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        if (array[i][property] === value) {
          return i
        }
      }
      return -1 // Return -1 if the value is not found
    },
    GetReward() {
      this.Loadmore = true
      this.$http.get('/lotto-suvarn/GetReward').then(ress => {
        // console.log(ress.data)
        this.reward = ress.data
        this.lottery_num = []

        this.Loadmore = false
        // ยังไม่เสร็จเหลือแสดงผลตรวจ statusType = 2 คือไม่ถูกรางวัล
      })
    },
    ChackReward() {
      const obj = {
        lottery_num: this.lottery_num,
        date: this.dateCheck.value,
      }
      this.$http.post('/lotto-suvarn/ChackReward', obj).then(ress => {
        this.chackdata = []

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < ress.data.length; i++) {
          if (ress.data[i].statusType === 1) {
            // eslint-disable-next-line no-restricted-syntax
            for (const items of ress.data[i].status_data) {
              // eslint-disable-next-line no-shadow
              const obj = {
                price: this.CalReward(items.reward),
                reward: items.reward,
              }
              this.chackdata.push(obj)
            }
            // const obj = {
            //   price: this.CalReward(ress.data[i].status_data[0].reward),
            //   reward: ress.data[i].status_data[0].reward,
            // }
            // this.chackdata.push(obj)
          }
        }
        // console.log(this.chackdata)
        if (this.chackdata.length > 0) {
          this.$refs['my-welcome'].show()
          this.hideModal()
          this.lottery_num = []
        } else {
          this.$refs['my-fail'].show()
          this.hideModal()
          this.lottery_num = []
        }
      })
    },
    // eslint-disable-next-line consistent-return
    CalReward(val) {
      if (val === 'รางวัลที่ 1') {
        return '6,000,000 วอน'
      }
      if (val === 'รางวัลเลขท้าย 2 ตัว') {
        return '2,000 วอน'
      }
      if (val === 'รางวัลเลขท้าย 3 ตัว') {
        return '4,000 วอน'
      }
      if (val === 'รางวัลเลขหน้า 3 ตัว') {
        return '4,000 วอน'
      }
      if (val === 'รางวัลที่ 2') {
        return '200,000 วอน'
      }
      if (val === 'รางวัลที่ 3') {
        return '80,000 วอน'
      }
      if (val === 'รางวัลที่ 4') {
        return '40,000 วอน'
      }
      if (val === 'รางวัลที่ 5') {
        return '20,000 วอน'
      }
      if (val === 'รางวัลข้างเคียงรางวัลที่ 1') {
        return '100,000 วอน'
      }
    },
    showdate(val) {
      return moment(val).tz('Asia/Bangkok').format('DD')
    },
    showmonth(val) {
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Month = moment(val).tz('Asia/Bangkok').format('M')
      return MonthTH[Month]
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    CalNameDate(Val) {
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Year = Number(moment(Val).tz('Asia/Bangkok').format('YYYY')) + 543
      const Month = moment(Val).tz('Asia/Bangkok').format('M')
      const Day = moment(Val).tz('Asia/Bangkok').format('DD')

      return `${Day} ${MonthTH[Month]} ${Year}`
    },
    CalNameDateEng(Val) {
      const MonthTH = [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const Year = Number(moment(Val).tz('Asia/Bangkok').format('YYYY'))
      const Month = moment(Val).tz('Asia/Bangkok').format('M')
      const Day = moment(Val).tz('Asia/Bangkok').format('DD')

      return `${Day} ${MonthTH[Month]} ${Year}`
    },
  },
}
</script>

<style scoped>
select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 16px;
  appearance: none; /* Remove the default arrow icon */
  cursor: pointer;
}

/* Add a custom arrow (requires a container for the <select>) */
.select-container {
  position: relative;
}

.select-container::after {
  content: "\25BC"; /* Unicode downward arrow */
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* You may need to add browser-specific prefixes for older browsers */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/* This will change the appearance when the select box is opened */
option {
  background: #f9f9f9;
  color: #333;
  padding: 1rem 1.5rem !important;
}

/* For Firefox */
option:checked {
  background: #532a76 !important;
  color: #fff !important;
}

/* For IE */
select option[selected] {
  background: #532a76 !important;
  color: #fff !important;
  padding: 1rem 1.5rem !important;
}

.text2 {
  font-size: 2em;
  font-weight: 900;
  background-image: linear-gradient(
    to left,
    #f2cc8f 0.23%,
    #fffdf6 49.3%,
    #ab925d 99.84%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}
</style>
